import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/nec/form');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async check(data) {
        try {
            const response = await axios.post(`/nec/form/check/${data.id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
