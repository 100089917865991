<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <el-table 
    :data="this.tableData" 
    class="w-100">
      <el-table-column sortable prop="payer.type" label="Payer Type"></el-table-column>
      <el-table-column sortable label="Payer Name">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.payer.type == 'business'">{{scope.row.payer.businessName}}</span>
            <span v-if="scope.row.payer.type == 'individual'">{{scope.row.payer.lastName}} {{scope.row.payer.firstName}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column sortable prop="recipient.type" label="Recipient Type"></el-table-column>
      <el-table-column sortable label="Recipient Name">
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.recipient.type == 'business'">{{scope.row.recipient.businessName}}</span>
            <span v-if="scope.row.recipient.type == 'individual'">{{scope.row.recipient.lastName}} {{scope.row.recipient.firstName}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column sortable prop="accountNumber" label="Account Number"></el-table-column>
      <el-table-column sortable prop="year" label="Year"></el-table-column>
      <el-table-column sortable prop="nonemployeeCompensation" label="Non employee Compensation"></el-table-column>
      <el-table-column sortable prop="federalIncomeTaxWithheld" label="Federal Income Tax Withheld"></el-table-column>

      <el-table-column width="100" sortable prop="status" label="Status">
        <template slot-scope="scope">
          <i v-if="scope.row.status==='Reviewed'" class="fal fa-check-circle text-success"></i>
          <i v-else class="fal fa-ban text-danger"></i>
          {{scope.row.status}}
        </template>
      </el-table-column>

      <el-table-column label="Operations" v-if="this.$store.getters.discriminator==='administrator'">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="mini" icon="el-icon-view" @click="view(scope.row,scope.$index)"></el-button>
            <el-button size="mini" icon="el-icon-check" @click="check(scope.row,scope.$index)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer title="Form 1099-NEC" :visible.sync="formData.drawer" direction="rtl" size="900px">
      <div class="pl-5 pr-5 pb-5">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-2">Payer</h3>
            <div class="card-body">
                <table class="table user-view-table m-0" v-if="formData.data">
                  <tbody>
                    <tr>
                      <td><strong>Type:</strong></td>
                      <td>{{formData.data.payer.type}}</td>
                    </tr>
                    <tr v-if="formData.data.payer.type === 'business'">
                      <td><strong>Ein:</strong></td>
                      <td>{{formData.data.payer.ein }}</td>
                    </tr>
                    <tr v-else>
                      <td><strong>SSN:</strong></td>
                      <td>{{formData.data.payer.ssn }}</td>
                    </tr>
                    <tr v-if="formData.data.payer.type === 'business'">
                      <td><strong>Name:</strong></td>
                      <td>{{formData.data.payer.businessName }}</td>
                    </tr>
                    <tr v-if="formData.data.payer.type === 'individual'">
                      <td><strong>First Name:</strong></td>
                      <td>{{formData.data.payer.firstName }}</td>
                    </tr>
                    <tr v-if="formData.data.payer.type === 'individual'">
                      <td><strong>Last Name:</strong></td>
                      <td>{{formData.data.payer.lastName }}</td>
                    </tr>
                    <tr v-if="formData.data.payer.type === 'individual'">
                      <td><strong>Suffix:</strong></td>
                      <td>{{formData.data.payer.suffix }}</td>
                    </tr>
                    <tr>
                      <td><strong>Disregarded Entity:</strong></td>
                      <td>{{formData.data.payer.disregarderEntity }}</td>
                    </tr>
                    <tr>
                      <td><strong>Address:</strong></td>
                      <td>{{formData.data.payer.address }}</td>
                    </tr>
                    <tr>
                      <td><strong>(Line 2)</strong>:</td>
                      <td>{{formData.data.payer.line2 }}</td>
                    </tr>
                    <tr>
                      <td><strong>State:</strong></td>
                      <td>{{formData.data.payer.state?formData.data.payer.state.name:''}}</td>
                    </tr>
                    <tr>
                      <td><strong>City:</strong></td>
                      <td>{{formData.data.payer.city?formData.data.payer.city.name:''}}</td>
                    </tr>
                    <tr>
                      <td><strong>Zip:</strong></td>
                      <td>{{formData.data.payer.zip }}</td>
                    </tr>
                    <tr>
                      <td><strong>Phone:</strong></td>
                      <td>{{formData.data.payer.phone }}</td>
                    </tr>
                    <tr>
                      <td><strong>Email:</strong></td>
                      <td>{{formData.data.payer.email }}</td>
                    </tr>
                    <tr>
                      <td><strong>Withholding / Tax State Id:</strong></td>
                      <td>{{formData.data.payer.withholding }}</td>
                    </tr>
                    <tr>
                      <td><strong>Last Filing:</strong></td>
                      <td>{{formData.data.payer.lastFiling?'Yes':'No' }}</td>
                    </tr>
                    <tr>
                      <td><strong>Client Id:</strong></td>
                      <td>{{formData.data.payer.clientId }}</td>
                    </tr>
                    <tr>
                      <td><strong>UnMask Recipient TIN:</strong></td>
                      <td>{{formData.data.payer.unmaskRecipientTin?'Yes':'No' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
          <div class="col-6">
            <h3 class="mb-2">Recipient</h3>
            <div class="card-body">
                <table class="table user-view-table m-0" v-if="formData.data">
                  <tbody>
                    <tr>
                      <td><strong>Type:</strong></td>
                      <td>{{formData.data.recipient.type}}</td>
                    </tr>
                    <tr v-if="formData.data.recipient.type === 'business'">
                      <td><strong>Ein:</strong></td>
                      <td>{{formData.data.recipient.ein }}</td>
                    </tr>
                    <tr v-else>
                      <td><strong>SSN:</strong></td>
                      <td>{{formData.data.recipient.ssn }}</td>
                    </tr>
                    <tr>
                      <td><strong>Tin Provided:</strong></td>
                      <td>{{formData.data.recipient.tinProvided?'Yes':'No' }}</td>
                    </tr>
                    <tr v-if="formData.data.recipient.type === 'business'">
                      <td><strong>Name:</strong></td>
                      <td>{{formData.data.recipient.businessName }}</td>
                    </tr>
                    <tr v-if="formData.data.recipient.type === 'individual'">
                      <td><strong>First Name:</strong></td>
                      <td>{{formData.data.recipient.firstName }}</td>
                    </tr>
                    <tr v-if="formData.data.recipient.type === 'individual'">
                      <td><strong>Last Name:</strong></td>
                      <td>{{formData.data.recipient.lastName }}</td>
                    </tr>
                    <tr v-if="formData.data.recipient.type === 'individual'">
                      <td><strong>Suffix:</strong></td>
                      <td>{{formData.data.recipient.suffix }}</td>
                    </tr>
                    <tr>
                      <td><strong>Atention To:</strong></td>
                      <td>{{formData.data.recipient.attentionTo }}</td>
                    </tr>
                    <tr>
                      <td><strong>Address:</strong></td>
                      <td>{{formData.data.recipient.address }}</td>
                    </tr>
                    <tr>
                      <td><strong>(Line 2):</strong></td>
                      <td>{{formData.data.recipient.line2 }}</td>
                    </tr>
                    <tr>
                      <td><strong>State:</strong></td>
                      <td>{{formData.data.recipient.state?formData.data.recipient.state.name:''}}</td>
                    </tr>
                    <tr>
                      <td><strong>City:</strong></td>
                      <td>{{formData.data.recipient.city?formData.data.recipient.city.name:''}}</td>
                    </tr>
                    <tr>
                      <td><strong>Zip:</strong></td>
                      <td>{{formData.data.recipient.zip }}</td>
                    </tr>
                    <tr>
                      <td><strong>Phone:</strong></td>
                      <td>{{formData.data.recipient.phone }}</td>
                    </tr>
                    <tr>
                      <td><strong>Email:</strong></td>
                      <td>{{formData.data.recipient.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <h3 class="mb-2">Form 1099-NEC</h3>
            <div class="card-body">
                <table class="table user-view-table m-0" v-if="formData.data">
                  <tbody>
                    <tr>
                      <td><strong>Account Number:</strong></td>
                      <td>{{formData.data.accountNumber }}</td>
                    </tr>
                    <tr>
                      <td><strong>Second TIN Notice:</strong></td>
                      <td>{{formData.data.secondTinNotice?'Yes':'No' }}</td>
                    </tr>
                    <tr>
                      <td><strong>Year:</strong></td>
                      <td>{{formData.data.year }}</td>
                    </tr>
                    <tr>
                      <td><strong>Nonemployee Compensation:</strong></td>
                      <td>${{new Intl.NumberFormat().format(formData.data.nonemployeeCompensation) }}</td>
                    </tr>
                    <tr>
                      <td><strong>Payer made direct sales totalling $5.000 or more of consumer products to recipient for sale:</strong></td>
                      <td>{{ formData.data.directSalesGreater5000?'Yes':'No' }}</td>
                    </tr>
                    <tr>
                      <td><strong>Federal Income Tax Withheld:</strong></td>
                      <td>${{new Intl.NumberFormat().format(formData.data.federalIncomeTaxWithheld) }}</td>
                    </tr>
                    <tr>
                      <td><strong>State Tax Withheld:</strong></td>
                      <td>
                        <div>
                          ${{new Intl.NumberFormat().format(formData.data.stateTaxWithheldValue1) }}
                        </div>
                        <div>
                          ${{new Intl.NumberFormat().format(formData.data.stateTaxWithheldValue2) }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>State / Payer's State no:</strong></td>
                      <td>
                        <div>
                         {{ formData.data.state1 }} / {{ formData.data.payerStateNo1 }}
                        </div>
                        <div>
                         {{ formData.data.state2 }} / {{ formData.data.payerStateNo2 }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>State Income:</strong></td>
                      <td>
                        <div>
                          ${{new Intl.NumberFormat().format(formData.data.stateIncome1) }}
                        </div>
                        <div>
                          ${{new Intl.NumberFormat().format(formData.data.stateIncome2) }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
          <div class="row">
            <div class="col">
              <el-button type="success" @click="check(formData.data, index)">Check Form</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import form from "@/services/api/formNec";

export default {

  data() {
    return {
      tableData: [],
      formData: {
        data: null,
        drawer: false
      },
      index: null,
    };
  },
  mounted() {
    form.get().then(response => {
      this.tableData = response;
    });
  },
  methods: {
    view(row, index) {
      this.formData.data = row
      this.formData.drawer = true
      this.index = index
    },
    check(row, index) {
      this.formData.drawer = false
      this.$swal({
          title: 'Are you sure?',
          text: "You will check this form!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#145388',
          confirmButtonText: 'Yes, do it!'
        }).then((result) => {
          if (result.value) {
            form
            .check(row)
            .then(response => {
              this.tableData.splice(index, 1, response);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error"
              });
            });
          }
        });
    }
  }
};
</script>
